@keyframes slideUpFade {
  0% { opacity: 0; transform: translateY(50px); }
  100% { opacity: 1; transform: translateY(0); }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2; /* Lighter shade of cream */
  font-family: 'Roboto', sans-serif;
}

.content {
  text-align: center;
}

.gavi {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 7em;  
  color: #4B4B4B;  /* Slightly darker shade for the text */
  animation: slideUpFade 1.5s forwards;
  text-shadow:  5px 5px 5px #4b4b4b38
}

.divider {
  width: 50%;
  height: 2px;
  background-color: #7C7C7C;  
  margin: 20px auto;
  opacity: 0;
  animation: slideUpFade 1.5s 1.5s forwards;
}

h2 {
  font-size: 2.5em;
  font-weight: 400;
  letter-spacing: 1.5px;
  color: #4B4B4B;
  opacity: 0;
  animation: slideUpFade 1.5s 3s forwards;
  text-shadow:  5px 5px 5px #4b4b4b38
}

.description {
  font-size: 1.5em;
  font-weight: 300;
  color: #6C6C6C; /* Lighter shade for the description */
  margin-top: 20px;
  opacity: 0;
  animation: slideUpFade 1.5s 4.5s forwards;
  text-shadow:  5px 5px 5px #6C6C6C38
}

.contact {
  font-size: 1.3em;
  font-weight: 300;
  color: #6C6C6C; /* Consistent with the description color */
  margin-top: 25px; /* Some space from the description */
  opacity: 0;
  animation: slideUpFade 1.5s 6s forwards; /* delayed to appear sequentially */
  text-shadow:  5px 5px 5px #6C6C6C38
}

.contact a {
  color: #4B4B4B; /* Slightly darker shade to highlight the link */
  text-decoration: none; /* Removes the default underline */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.contact a:hover {
  color: #7C7C7C; /* Changes color on hover */
}
